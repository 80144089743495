import {
    useGetRequest,
    RequestResponse,
} from "../../hook/useRequest";
import {AppSettings} from "../../AppSettings";
import {getToken} from "../../utils/storage/Token";

const API_URL_STATS = AppSettings.API_FREELANCERIE + "/stats";

const useFetchStats = (idUser: number, year: number, type:"urssaf"|"annual", forceUpdated = 0): RequestResponse => {
    const config = {headers: {Authorization: getToken()}};
    return useGetRequest(`${API_URL_STATS}/${idUser.toString()}&${year.toString()}&${type}`, forceUpdated, config);
};


export {useFetchStats};
