import React, {useEffect, useState} from "react";
import {clearToken, isAdminWithToken, getUserNameWithToken} from "../../../utils/storage/Token";
import {getIdWithToken} from "../../../utils/storage/Token";
import {useFetchConfigUser} from "../../../service/AccountService";
import {Md5} from 'ts-md5';
import "./HeaderDashboard.scss"
import {PostRequest} from "../../../hook/useRequest";

type HeaderDashboardProps = {
    setMenuSelected: any;
};

const HeaderDashboard: React.FunctionComponent<HeaderDashboardProps> = ({setMenuSelected}) => {
    const {data: configUser} = useFetchConfigUser(getIdWithToken());
    const [avatarUrl, setAvatarUrl] = useState("00000000000000000000000000000000");
    const [hamburgerToggle, setHamburgerToggle] = useState(false);

    //Création du custom Hash gravatar
    useEffect(() => {
        if (configUser) {
            const md5 = new Md5();
            const mail = configUser.user.userInfo.userSMTP;
            md5.appendStr(mail);
            let hashMail = "00000000000000000000000000000000";
            let ret = md5.end() as string;
            if (ret) {
                hashMail = ret;
            }
            setAvatarUrl(hashMail);
        }
    }, [configUser]);

    const onClick = async (typeMenu: string) => {
        if (typeMenu === "logout") {
            await PostRequest("/authentication/logout", {}).then(() => {
                clearToken();
                window.location.reload();
            }).catch(error => console.log(error));
        }
        else if (typeMenu === "card") {
            window.open(`/user/${getUserNameWithToken()}`,'_blank');
        }
        else {
            setMenuSelected(typeMenu);
        }
        setHamburgerToggle(false);
    }
    const toggleHamburger = () => {
        setHamburgerToggle(!hamburgerToggle);
    }

    return (
        <nav className={"headerDashboard"}>
            <div className={"headerLogo"}>
                <img src={`${process.env.PUBLIC_URL}/logo_fc.svg`} style={{cursor: "pointer"}}
                     alt={"logo_fc"} height={45} width={45} onClick={() => {
                    window.location.reload()
                }}/>
            </div>
            <div className={"headerTitle"} onClick={() => {
                window.location.reload()
            }}>FREE COMPTA
            </div>
            <div className={hamburgerToggle ? "menuBar show" : "menuBar"}>

                <div className={"spacerHeader"}></div>
                <div className={"menuItem"}>
                    <div className={"dropdown"}>
                        <div>Business</div>
                        <div className="dropdownContent">
                            <div className={"dropdownItem"} onClick={() => onClick("customer")}>Clients</div>
                            <div className={"dropdownItem"} onClick={() => onClick("businessDeal")}>Contrats</div>
                            <div className={"dropdownItem"} onClick={() => onClick("billing")}>Facturations</div>
                            <div className={"dropdownItem"} onClick={() => onClick("outlay")}>Frais de déplacement</div>
                            <div className={"dropdownItem"} onClick={() => onClick("explorer")}>Mes fichiers</div>
                        </div>
                    </div>
                </div>
                <div className={"spacerHeader"}></div>
                <div className={"menuItem"}>
                    <div className={"dropdown"}>
                        <div>Déclarations</div>
                        <div className="dropdownContent">
                            <div className={"dropdownItem"} onClick={() => onClick("resume_dec")}>Résumé</div>
                            <div className={"dropdownItem"} onClick={() => onClick("urssaf")}>URSSAF</div>
                            <div className={"dropdownItem"} onClick={() => onClick("tva")}>TVA</div>
                            <div className={"dropdownItem"} onClick={() => onClick("help_declaration")}>Aide</div>
                        </div>
                    </div>
                </div>
                <div className={"spacerHeader"}></div>
                <div className={"menuItem"} onClick={() => onClick("calendar")}>Planning</div>
                <div className={"spacerHeader"}></div>
                <div className={"menuItem"} onClick={() => onClick("address")}>Adresses</div>
                <div className={"spacerHeader"}></div>
                <div className={"menuItem"}>
                    <div className={"dropdown"}>
                        <div>Statistiques</div>
                        <div className="dropdownContent">
                            <div className={"dropdownItem"} onClick={() => onClick("stat_urssaf")}>Cumul URSSAF</div>
                            <div className={"dropdownItem"} onClick={() => onClick("stat_annual")}>Cumul Annuel</div>
                        </div>
                    </div>
                </div>
                <div className={"spacerHeader"}></div>
                <div className={"menuItem"}>
                    <div className={"dropdown"}>
                        <div>Attestations</div>
                        <div className="dropdownContent">
                            <div className={"dropdownItem"}
                                 onClick={() => window.open(`https://api-avis-situation-sirene.insee.fr/identification/pdf/${configUser.user.userInfo.companySiret.replaceAll(" ", "")}`, "_blank")}>
                                Avis de situation SIRENE
                            </div>
                            <div className={"dropdownItem"}
                                 onClick={() => window.open(`${process.env.PUBLIC_URL}/pdf/ash/0`, "_blank")}>Non
                                assujettissement TVA
                            </div>
                            <div className={"dropdownItem"}
                                 onClick={() => window.open(`${process.env.PUBLIC_URL}/pdf/ash/1`, "_blank")}>Non
                                Emplois Travailleurs étranger
                            </div>
                            <div className={"dropdownItem"}
                                 onClick={() => window.open(`${process.env.PUBLIC_URL}/pdf/ash/2`, "_blank")}>Marché
                                Public/Privé
                            </div>
                            <div className={"dropdownItem"}
                                 onClick={() => window.open(`${process.env.PUBLIC_URL}/pdf/ash/3`, "_blank")}>Jours
                                enfant malade
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"spacerHeader"}></div>
                <div className={"menuItem"}>
                    <div className={"dropdown"}>
                        <div>Services</div>
                        <div className="dropdownContent">
                            <div className={"dropdownItem"}
                                 onClick={() => window.open("https://www.shine.fr", "_blank")}>Shine
                            </div>
                            <div className={"dropdownItem"}
                                 onClick={() => window.open("https://www.autoentrepreneur.urssaf.fr", "_blank")}>URSSAF
                            </div>
                            <div className={"dropdownItem"}
                                 onClick={() => window.open("https://cfspro-idp.impots.gouv.fr", "_blank")}>Impots
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"spacerHeader"}></div>
                <div className={"menuItem divCompte"}>
                    <div className={"dropdown"}>
                        <img className={"logoCompte"}
                             src={"https://www.gravatar.com/avatar/" + avatarUrl}
                             alt="gravatar"/>
                        <div className="dropdownContent dropdownContentLogo">
                            <div className={"dropdownItem"} onClick={() => onClick("config")}>Configuration Compte</div>
                            <div className={"dropdownItem"} onClick={() => onClick("password")}>Modification mot de
                                passe
                            </div>
                            <div className={"dropdownItem"} onClick={() => onClick("card")}>Carte de visite</div>
                            {isAdminWithToken() &&
                                <React.Fragment>
                                    <div className={"dropdownItemSeparator"}></div>
                                    <div className={"dropdownItem"} onClick={() => onClick("adminusers")}>Administration
                                        des utilisateurs
                                    </div>
                                </React.Fragment>
                            }
                            <div className={"dropdownItemSeparator"}></div>
                            <div className={"dropdownItem"} onClick={() => onClick("logout")}>Déconnexion</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="menuToggle" onClick={() => toggleHamburger()}>
                <span className="iconBar"></span>
                <span className="iconBar"></span>
                <span className="iconBar"></span>
            </div>
        </nav>
    );
}

export default HeaderDashboard;