import React, {useContext, useState} from "react";
import "./ContentBusinessDeal.scss";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {getIdWithToken} from "../../../utils/storage/Token";
import {DeleteBusiness, useFetchBusinessDealContent} from "../../../service/Business/BusinessService";
import FCSpinner from "../../FCObjets/FCSpinner";
import {faBox, faEnvelope, faFilePdf, faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import FCIconButton from "../../FCObjets/FCIconButton";
import {TypeForm} from "../../../types/EnumTypeForm";
import {BusinessDealInterface} from "../../../service/Business/Interfaces/BusinessDealInterface";
import StatusBusinessDeal from "../../../utils/Business/StatusBusinessDeal";
import FCAlertMsgErrorAPI from "../../FCObjets/FCAlertMsg/FCAlertMsgErrorAPI";
import {EnumTypeMail} from "../../../types/Business/EnumTypeMail";
import FCContentModalFL, {DataForm} from "../../FCObjets/Form/FCContentModalFL";
import FCTag from "../../FCObjets/FCTag";
import FCFloatButton from "../../FCObjets/FCFloatButton";

function ContentBusinessDeal() {

    let {cptUpdated, setUpdated} = useContext(EventUpdatedContext);
    const {data: business, loading, error} = useFetchBusinessDealContent(getIdWithToken(), cptUpdated);
    const [isModalOpened, setIsModalOpened] = useState(false)
    const [data, setData] = useState<DataForm>({
        type: TypeForm.EMPTY,
        data: undefined,
    });

    // Permettant l'ouverture du modal
    const gestionModal = (open: boolean, e: BusinessDealInterface | any | undefined, type: TypeForm | undefined) => {
        setIsModalOpened(open);
        setData({type: ((open && type) ? type : TypeForm.EMPTY), data: e})
    }

    return (
        <FCContentModalFL form={data} onModalFormFinished={() => gestionModal(false, undefined, undefined)}>
            <div className={"content-page-business-deal"}>
                <h1 className="content-heading">
                    Liste des contrats
                </h1>
                {
                    !isModalOpened && <FCFloatButton items={[]} icon={faPlus} positionBottom={"70px"}
                                                     handlerOnClick={() => gestionModal(true, undefined, TypeForm.BUSINESS)}/>
                }

                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Nom Client</th>
                        <th>Nom</th>
                        <th>Numéro</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Début</th>
                        <th>Fin</th>
                        <th>Tot. à Fact. HT</th>
                        <th>Tot. Fact. HT</th>
                        <th>Reste à Fact. HT</th>
                        <th>Total Fact. HT en cours</th>
                        <th>
                            <div className="row-content">
                                Actions
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading && <tr>
                            <td><FCSpinner size={"middle"}/></td>
                        </tr>
                    }
                    {
                        business && business.map((e: {
                            typeMail?: EnumTypeMail,
                            id?:number,
                            business: BusinessDealInterface,
                            totalToInvoiced: number,
                            totalInvoiced: number,
                            remainToInvoice: number,
                            totalInvoiceOpen: number
                        }, index: React.Key) => {
                            return (<tr key={index}>
                                    <td data-label="Nom Client">{e.business.customer.companyName}</td>
                                    <td data-label="Nom">{e.business.name}</td>
                                    <td data-label="Numéro">{e.business.details.number}</td>
                                    <td data-label="Description">{e.business.description}</td>
                                    <td data-label="Status"><FCTag value={StatusBusinessDeal.toString(e.business.state)}
                                                                   color={StatusBusinessDeal.toColor(e.business.state)}
                                                                   style={{fontWeight: "bold"}}/></td>
                                    <td data-label="Début">{new Date(e.business.details.begin).toLocaleDateString()}</td>
                                    <td data-label="Fin">{new Date(e.business.details.end).toLocaleDateString()}</td>
                                    <td data-label="Tot. à Fact. HT">{e.totalToInvoiced} €</td>
                                    <td data-label="Tot. Fact. HT">{e.totalInvoiced} €</td>
                                    <td data-label="Reste à Fact. HT">{e.remainToInvoice.toFixed(2)} €</td>
                                    <td data-label="Total Fact. HT en cours">{e.totalInvoiceOpen} €</td>
                                    <td data-label="Actions">
                                        <div className="row-content">
                                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <FCIconButton icon={faPen} tooltip={"Modifier"} color="green"
                                                                  onClickHandler={() => {
                                                                      gestionModal(true, e.business, TypeForm.BUSINESS)
                                                                  }}/>
                                                    <FCIconButton icon={faTrash} tooltip={"Supprimer"} color="red"
                                                                  onClickHandler={() => {
                                                                      const onConfirm = () => {
                                                                          DeleteBusiness(e.business).then(() => setUpdated())
                                                                      }
                                                                      const payload = {
                                                                          title: `Suppression du contrat ${e.business.name}`,
                                                                          msg: "Êtes-vous sûr de vouloir supprimer ce contrat ?",
                                                                          onConfirm: onConfirm
                                                                      }
                                                                      gestionModal(true, payload, TypeForm.CONFIRM);

                                                                  }}/>
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row"}}>

                                                    <FCIconButton icon={faFilePdf} tooltip={"Proposition commerciale"}
                                                                  onClickHandler={() => {
                                                                      window.open(`/pdf/business/${e.business.id}`, '_blank')
                                                                  }}/>
                                                    <FCIconButton icon={faEnvelope} color={"orange"}
                                                        tooltip={"Envoyer contrat"}  onClickHandler={() => { e.typeMail = EnumTypeMail.BUSINESS ;e.id = e.business.id ; return gestionModal(true, e, TypeForm.SEND_MAIL)}}  />
                                                    <FCIconButton icon={faBox} tooltip={"Archiver contrat"}
                                                                  onClickHandler={() => gestionModal(true, e.business, TypeForm.UPLOAD_BUSINESS)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {
                        error && <tr>
                            <td>
                                <div>{error.message}</div>
                                <FCAlertMsgErrorAPI error={error}/>
                            </td>
                        </tr>
                    }

                    </tbody>
                </table>
            </div>
        </FCContentModalFL>
    );
}

export default ContentBusinessDeal;