import React, {useContext, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, ArcElement, BarElement, Chart,
} from 'chart.js';
import {Bar, Line, Pie} from 'react-chartjs-2';
import {getIdWithToken} from "../../../utils/storage/Token";
import {EventUpdatedContext} from "../../../store/EventUpdatedContext";
import {useFetchStats} from "../../../service/Business/StatsService";
import TypeDate from "../../../types/TypeDate";
import "./ContentStatistic.scss";
import FCIconButton from "../../FCObjets/FCIconButton";
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";

type contentStatProps = {
    type: "urssaf"|"annual";
};

function ContentStatistic(props:contentStatProps) {

    const [year, setYear] = useState<number>(2024);
    let {cptUpdated} = useContext(EventUpdatedContext);
    const {data: stat} = useFetchStats(getIdWithToken(), year, props.type, cptUpdated);

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement,
        BarElement);

    const labels = TypeDate.monthsLong;

    Chart.defaults.color = "white";

    // ----------------- Configure Line Chart
    const optionsLineChart = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            legend: {
                position: 'bottom' as const,
                labels: {
                    color: "white"
                }
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (item: any) =>
                        `${item.dataset.label}: ${item.formattedValue} €`,
                },
            },
        },
        scales: {
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                min: 0
            },
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
                min: 0,
                max: 500
            },
        },
    };

    const dataLineChart = {
        labels,
        color: "white",
        datasets: [
            {
                label: 'Total net',
                data: stat ? stat.dataTotalNet : [],
                borderColor: 'rgba(165, 94, 234, 0.8)',
                backgroundColor: 'rgba(165, 94, 234, 0.8)',
                yAxisID: 'y',
            },
            {
                label: 'Chiffre affaire net',
                data: stat ? stat.dataCANet : [],
                borderColor: 'rgba(46, 204, 113, 0.8)',
                backgroundColor: 'rgba(46, 204, 113, 0.8)',
                yAxisID: 'y',
            },

            {
                label: 'TVA déductible',
                data: stat ? stat.dataTvaDeductible : [],
                borderColor: 'rgba(0, 168, 255, 0.8)',
                backgroundColor: 'rgba(0, 168, 255, 0.8)',
                yAxisID: 'y1',
            },
        ],
    };

    // ------------------ Configure stacked chart
    const optionsStackedChart = {
        plugins: {
            title: {
                display: false,
            },
            legend: {
                position: 'bottom' as const,
            },
            tooltip: {
                callbacks: {
                    label: (item: any) =>
                        `${item.dataset.label}: ${item.formattedValue} €`,
                },
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const dataStackedChart = {
        labels,
        datasets: [
            {
                label: 'Chiffre affaire net',
                data: stat ? stat.dataCANet : [],
                backgroundColor: 'rgba(46, 204, 113, 0.8)',
            },
            {
                label: 'URSSAF',
                data: stat ? stat.dataUrssaf : [],
                backgroundColor: 'rgba(253, 121, 168, 0.8)',
            },
            {
                label: 'TVA',
                data: stat ? stat.dataTvaDeductible : [],
                backgroundColor: 'rgba(0, 168, 255, 0.8)',
            }
        ],
    };

    // --------------- Configure Pie Chart
    const dataPieChart = {
        labels: ['Cumul annuel net', 'Cumul annuel URSSAF', 'Cumul annuel TVA déductible'],
        datasets: [
            {
                //label: '# of Votes',
                data: stat ? [stat.cumulAnnuelCANet, stat.cumulAnnuelUrssaf, stat.cumulAnnuelTvaDeductible] : [],
                backgroundColor: [
                    'rgba(46, 204, 113, 0.8)',
                    'rgba(253, 121, 168, 0.8)',
                    'rgba(0, 168, 255, 0.8)',
                ],
                borderColor: [
                    'rgba(46, 204, 113, 0.8)',
                    'rgba(253, 121, 168, 0.8)',
                    'rgba(0, 168, 255, 0.8)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const optionsPieChart = {
        plugins: {
            title: {
                display: false,
            },
            legend: {
                position: 'bottom' as const,
            },
            tooltip: {
                callbacks: {
                    label: (ttItem: any) => (`${ttItem.parsed} €`)
                }
            }
        },
        responsive: true
    };

    return (
        <React.Fragment>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}>
                <FCIconButton icon={faCaretLeft} onClickHandler={() => setYear(year - 1)} tooltip={""}/>
                <h1 className="content-heading">
                    Statistique {year}
                </h1>
                <FCIconButton icon={faCaretRight} onClickHandler={() => setYear(year + 1)} tooltip={""}/>
            </div>
            {
                stat &&

                    <div className={"contentStatistic"}>

                        <div className={"bandeauGauche"}>
                            <div className={"resumeStat"}>
                                <div>Brut annuel : {(stat.cumulAnnuelCANet + stat.cumulAnnuelUrssaf).toFixed(2)} €</div>
                                <div>Net annuel : {stat.cumulAnnuelCANet.toFixed(2)} €</div>
                                <div>Net annuel + TVA : {(stat.cumulAnnuelCANet + stat.cumulAnnuelTvaDeductible).toFixed(2)} €</div>
                            </div>
                            <Line style={{flex: "1"}} options={optionsLineChart} data={dataLineChart}/>
                        </div>
                        <div className={"bandeauDroit"}>
                            <div className={"stylePieChart"}><Pie options={optionsPieChart} data={dataPieChart}/></div>
                            <div className={"styleStackedChart"}><Bar options={optionsStackedChart}
                                                                  data={dataStackedChart}/></div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default ContentStatistic;